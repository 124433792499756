<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <img src="../assets/sales/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">SALES</h1>
        </div>
        <div class="title-middle">
          <h1 class="title-h1">&SERVICE</h1>
        </div>
        <div class="title-bottom"
             v-if="$store.state.isZH"
             id="title">
          销售与服务
        </div>
      </div>
      <div class="desc">
        <div class="de"
             v-if="$store.state.isZH">
          欧瑞捷致力于国内外市场的开拓。凭借卓越的产品质量、注册技术支持及销售服务，公司产品销售量稳居行业前列。截至目前，公司产品销售网络除已覆盖全国之外，大量出口到国际市场，获得了包括欧洲、北美、南美、东南亚、中东等地区和国家的客户一致好评。
        </div>
        <div class="ENdesc"
             v-if="!$store.state.isZH">
          <span style="margin-bottom: 15px; width: 98%;">Xiamen Origin is striving for expanding both domestic and overseas market and eager to seek reliable business partners abroad.</span>
          <span style="width: 100%;">With excellent product quality, technical support, and sales services, Origin’ s products rank in the forefront of domestic market; in the meantime, the company regularly exports to Europe, North America, South America, the Middle East and Asia, and has won unanimous praise from customers both domestic and abroad.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sales'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    // height: 100%;
    color: #fff;
    height: 100%;
    padding: 22% 15% 15%;
    background: url("../assets/sales/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 5%;
      box-sizing: border-box;
      .img-icon {
        height: 65px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: bold;
      }
    }
    .desc {
      display: flex;
      justify-content: flex-end;
      .de {
        display: flex;
        justify-content: flex-end;
        width: 48%;
        font-size: 21px;
        line-height: 40px;
      }
      .ENdesc {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // width: 48%;
        width: 500px;
        font-size: 18px;
        line-height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  // 顶部标题
  .container {
    .content {
      padding: 20% 6%;
      box-sizing: border-box;

      .title {
        .title-top {
          .img-icon {
            width: 30px;
            height: 30px;
          }
        }
        .title-h1 {
          font-size: 30px !important;
        }
      }

      #title {
        font-size: 22px;
        font-weight: 600;
      }

      .desc {
        display: flex;
        justify-content: flex-start;
        .de {
          width: 80%;
          font-size: 15px;
          line-height: 22px;
        }
        .ENdesc {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          // width: 98%;
          // width: 324px;
          font-size: 12px;
          line-height: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .content {
      .title {
        padding-bottom: 40%;
        .title-h1 {
          font-size: 55px;
        }
        .img-icon {
          width: 50px;
          height: 30px;
        }
        .title-bottom {
          font-size: 35px;
        }
      }
      .de {
        width: 68%;
        font-size: 20px;
        line-height: 34px;
      }
      .desc {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}
</style>